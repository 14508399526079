<template>
  <div id="data-statistics-setting">
    <el-container class="fyu-appcontainer">
      <el-main class="">
        <p class="title">
          设置常用统计数据<span class="sub-title"
            >（可设置3-6个，拖动可排序）</span
          >
        </p>
        <draggable
          v-model="setList[0].details"
          class="set-list"
          ghost-class="set-ghost"
          group="setCard"
          animation="200"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group class="set-item-container">
            <div
              class="set-item set-item-choosen"
              :class="{ 'set-item-draggable': item.draggableFlag }"
              v-for="(item, index) in setList[0].details"
              :key="item.requestId"
              @mouseenter="item.draggableFlag = true"
              @mouseleave="item.draggableFlag = false"
            >
              <img :src="dataStatisticsIcon[item.optionImg]" class="set-item-icon" />
              <div class="set-item-details">
                <p class="set-item-title">{{ item.configName }}</p>
              </div>
              <i
                class="set-del-btn"
                v-show="item.draggableFlag"
                @click="removeCard(item, index)"
              ></i>
              <i class="set-drag-icon" v-show="item.draggableFlag"></i>
            </div>
          </transition-group>
        </draggable>
        <div class="data-statistics-btns">
          <div class="back-btn" @click="backWorkbench">返回</div>
          <div class="save-btn" @click="saveCard">保存</div>
        </div>
        <div class="all-modules">
          <div
            class="each-modules"
            v-for="(item, index) in allList"
            :key="index"
          >
            <p class="title">
              {{ item.module }}
            </p>
            <div class="set-list">
              <div
                class="set-item"
                :class="{
                  'set-item-hovering':
                    moduleItem.hovering && !moduleItem.picked,
                  'set-item-picked': moduleItem.isHas,
                }"
                v-for="(moduleItem, moduleIndex) in item.details"
                :key="moduleItem.requestId"
                @mouseenter="mouseenter(index, moduleIndex)"
                @mouseleave="mouseleave(index, moduleIndex)"
                @click="pickCard(index, moduleIndex, moduleItem)"
              >
                <img :src="dataStatisticsIcon[moduleItem.optionImg]" class="set-item-icon" />
                <div class="set-item-details">
                  <p class="set-item-title">
                    {{ moduleItem.configName }}
                  </p>
                </div>
                <div
                  class="add-btn"
                  v-show="moduleItem.hovering && !moduleItem.isHas"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { GetAlreadyConfigured, PostSaveWorkConfigs } from "@/api/index.js";
export default {
  name: "datastatistics",
  components: {  draggable },
  data() {
    return {
      drag: false,
            dataStatisticsIcon: [
        require("@/assets/img/TJ0.png"),
        require("@/assets/img/TJ1.png"),
        require("@/assets/img/TJ2.png"),
        require("@/assets/img/TJ3.png"),
        require("@/assets/img/TJ4.png"),
        require("@/assets/img/TJ5.png"),
        require("@/assets/img/TJ6.png"),
        require("@/assets/img/TJ7.png"),
        require("@/assets/img/TJ8.png"),
        require("@/assets/img/TJ9.png"),
        require("@/assets/img/TJ10.png"),
        require("@/assets/img/TJ11.png"),
        require("@/assets/img/TJ12.png"),
        require("@/assets/img/TJ13.png"),
        require("@/assets/img/TJ14.png"),
        require("@/assets/img/TJ15.png"),
        require("@/assets/img/TJ16.png"),
        require("@/assets/img/TJ17.png"),
        require("@/assets/img/TJ18.png"),
        require("@/assets/img/TJ19.png"),
        require("@/assets/img/TJ20.png"),
      ],
      setList: [
        {
          details: [
            {
              configName: "社保月度账单",
              isHas: true,
              months: null,
              optionImg: "0",
              requestId: "a1b50f19-1713-4b03-a642-d0db87e1d791",
            },
            {
              configName: "月度发薪人数",
              isHas: true,
              months: null,
              optionImg: "0",
              requestId: "7293bda1-b739-425e-bcd7-bece7d7e17c3",
            },
          ],
        },
      ],
      allList: [],
      removeRecord: [],
    };
  },
  methods: {
    async getDate() {
      let resSetList = await GetAlreadyConfigured("dataStatistics");
      let resAllList = resSetList.data.slice(1);
      // console.log("resSetList",resSetList.data);
      // let IssuedFestival = await this.$axios.get(
      //   "/gifts/api/Report/hr_coupon_giveout_count"
      // ); //已发放福利数
      // let anniu=await this.$axios.get("/workbench/api/Workbench/ConfigSetData") //已发放福利数
      // let NotUse=await this.$axios.get("/gifts/api/Report/hr_gift_ungiveout_count") //未使用福利
      // let NotClaimed=await this.$axios.get("/gifts/api/Report/hr_coupon_unused_count") //未领取福利
      // let workbench=await this.$axios.get("/sala/api/SalaryCommon/CountForWorkbench") // 工作台数据
      // let thisMonthPoint=await this.$axios.get("/point/api/Report/hr_point_giveout_stat") // 本月发放积分
      // let PointBalance = await this.$axios.get("/point/api/Purse/hr"); // 积分余额

      // console.log(PointBalance);
      // console.log(IssuedFestival.data);
      if (resSetList.status == 200) {
        for (let obj of resSetList.data[0].details) {
          obj.draggableFlag = false;
        }
        this.setList = resSetList.data;
        this.manageData(resAllList);
      } else {
        this.$message.error(resSetList.statusText);
      }

      // this.allList[0].OptionList[4].OptionData=IssuedFestival.data  //已发放福利数
      // this.allList[0].OptionList[5].OptionData=NotUse.data //未使用福利
      // this.allList[0].OptionList[6].OptionData=NotClaimed.data  //未领取福利
      // this.allList[0].OptionList[8].OptionData=thisMonthPoint.data  //本月发放积分
      // this.allList[0].OptionList[7].OptionData=PointBalance.data.balancePoints  //积分余额
      // this.allList[2].OptionList[2].OptionData=workbench.data.未完成发放计划
      // this.allList[2].OptionList[3].OptionData=workbench.data.工资条发放数
    },
    manageData(allList) {
      // 初始化所有卡片hovering状态, picked状态
      for (let i = 0; i < allList.length; i++) {
        let ModularOptionList = allList[i].details;
        for (let j = 0; j < ModularOptionList.length; j++) {
          let ModularOption = ModularOptionList[j];
          ModularOption.hovering = false;
          let Id = ModularOption.requestId;
          for (let k = 0; k < this.setList.length; k++) {
            if (Id == this.setList[k].requestId) {
              ModularOption.isHas = true;
            }
          }
        }
      }
      this.allList = allList;
    },
    mouseenter(parentIndex, index) {
      let newVal = this.allList[parentIndex];
      newVal.details[index].hovering = true;
      this.$set(this.allList, parentIndex, newVal);
    },
    mouseleave(parentIndex, index) {
      let newVal = this.allList[parentIndex];
      newVal.details[index].hovering = false;
      this.$set(this.allList, parentIndex, newVal);
    },
    pickCard(parentIndex, moduleIndex, moduleItem) {
      if (moduleItem.isHas) return;
      if (this.setList[0].details.length >= 6) {
        this.$message({
          message: "设置已满，删除一些后再试试吧！",
          type: "warning",
          showClose: true,
        });
        return;
      }

      let obj = {
        configName: moduleItem.configName,
        hovering: moduleItem.hovering,
        draggableFlag: moduleItem.draggableFlag,
        isHas: moduleItem.isHas,
        optionImg: moduleItem.optionImg,
        requestId: moduleItem.requestId,
      };
      this.setList[0].details.push(obj);
      this.allList[parentIndex].details[moduleIndex].isHas = true;
    },
    removeCard(item, index) {
      if (this.setList[0].details.length <= 3) {
        this.$message({
          message: "不能更少了……再添加一些吧！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let id = item.requestId;
      for (let i = 0; i < this.allList.length; i++) {
        let ModularOptionList = this.allList[i].details;
        for (let j = 0; j < ModularOptionList.length; j++) {
          let ModularOption = ModularOptionList[j];
          if (id == ModularOption.requestId) {
            ModularOption.isHas = false;
            this.setList[0].details.splice(index, 1);
            return;
          }
        }
      }
    },
    async saveCard() {
      let arr = this.setList[0].details;
      let ids = [];
      for (let i = 0; i < arr.length; i++) {
        ids.push(arr[i].requestId);
      }
      let obj = {
        ids: ids,
        modularType: "dataStatistics",
      };
      const res = await PostSaveWorkConfigs(obj);
      if (res.data) {
        this.$message({
          message: "保存设置成功",
          type: "success",
          showClose: true,
        });
        // this.getDate();
      }
    },
    backWorkbench() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.getDate();
  },
};
</script>

<style scoped>
@import url("../../assets/css/workbench/dataStatistics.css");
#data-statistics-setting {
  background: rgba(244, 245, 249, 1);
  height: 100%;
}
.fyu-appcontainer {
  height: 100%;
}
</style>